import config from "../../config";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import minus from "../../assets/img/svg/minus.svg";
import plus from "../../assets/img/svg/plus.svg";
import minusmuted from "../../assets/img/svg/minusmuted.svg";
import cartclearitem from "../../assets/img/svg/cartclearitem.svg";
import { ICartItem } from "../../services/CartService";
import usePlaceStore from "../../zustand/place/place-store";
import useCurrencyStore from "../../zustand/currency/currency-store";
import useCartStore from "../../zustand/cart/cart-store";

interface CartItemProps {
  item: ICartItem;
}

export function CartItem({ item }: CartItemProps) {
  const { increment, decrement, remove } = useCartStore((state) => state);
  const { currency } = useCurrencyStore((state) => state);
  const { place } = usePlaceStore((state) => state);

  const onClickPlus = () => {
    if (!place) return;
    increment(item.nomenclatureid, place.placeid);
  };

  const onClickMinus = () => {
    decrement(item.nomenclatureid);
  };

  const onClickRemove = () => {
    if (window.confirm("Вы действительно хотите удалить товар?")) {
      remove(item.nomenclatureid);
    }
  };
  console.log(item);

  return (
    <>
      <Row className="align-items-center justify-content-between text-center mb-3">
        <div className="col-12 d-sm-none mb-2 text-start">
          <Link key={item.nomenclatureid} to={`/nomenclature/${item.nomenclatureid}`}>
            <span className="item-name fs-4 fw-bold">{item.nomenclaturename}</span>
          </Link>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
          <Link key={item.nomenclatureid} to={`/nomenclature/${item.nomenclatureid}`}>
            <img className="p-5 mw-100" src={`${config.API_URL}/nomenclature/image/` + item.nomenclatureimageid} alt="" />
          </Link>
        </div>
        <div className="d-none d-sm-block col-xl-5 col-lg-4 col-md-4 col-sm-4 col-12 p-3 text-start">
          <Link key={item.nomenclatureid} to={`/nomenclature/${item.nomenclatureid}`}>
            <span className="item-name text-b">{item.nomenclaturename}</span>
          </Link>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4 prod-descr d-flex justify-content-center align-items-center text-center">
          {item.cnt > 1 && (
            <span className="minus" onClick={onClickMinus}>
              <img src={minus} alt="minus" />
            </span>
          )}
          {item.cnt === 1 && (
            <span className="minus">
              <img src={minusmuted} alt="minus" />
            </span>
          )}
          <input
            type="text"
            disabled
            name="name"
            className="count w-25 m-1 text-center rounded-3"
            style={{ border: "#7c7c7c 2px solid" }}
            value={item.cnt}
          />
          <span className="plus " onClick={onClickPlus}>
            <img src={plus} alt="" />
          </span>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4 p-0 text-end">
          <span className="item-cost fw-bold text-b">
            {(item.temporarysum * item.cnt).toLocaleString()} {currency.nameshort}
          </span>
        </div>
        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 p-0">
          <span className="delete-item-btn" onClick={onClickRemove}>
            <img src={cartclearitem} alt="" />
          </span>
        </div>
        <hr className="text-dark" />
      </Row>
    </>
  );
}

export default CartItem;
