import $api from "../http";
import { AxiosResponse } from "axios";

export type ICurrency = {
  currencyid: string;
  name: string;
  namesys: string;
  nameshort: string;
};

export default class CurrencyService {
  static getCurrency(placeid: string): Promise<AxiosResponse<ICurrency>> {
    return $api.get<ICurrency>(`currency`, { params: { placeid } });
  }
}
