import React from "react";
import UserProfileService from "../../services/UserProfileService";
import useUserStore from "../../zustand/user/user-store";

export default function ChangeEmailItem() {
  const { user, updateUser } = useUserStore((state) => state);
  const [email, setEmail] = React.useState(user?.email);

  const onClickApply = () => {
    if (!email) return;
    UserProfileService.updateEmail({ email })
      .then((res) => {
        updateUser({ ...user, email });
        window.location.reload();
      })
      .catch((e: any) => {
        // console.log('except', e);
        if (e.response) {
          alert(e.response.data.message);
        } else {
          alert(e.message);
        }
      });
  };

  return (
    <div>
      <div className="mb-3">
        <label className="form-label">Адрес электронной почты</label>
        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />

        <div className="form-text">Мы никогда никому не передадим вашу электронную почту.</div>
      </div>

      <button type="submit" className="btn style-btn-action" onClick={onClickApply}>
        Применить
      </button>
    </div>
  );
}
