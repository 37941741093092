import $api from "../http";
import { AxiosResponse } from "axios";

export type IInvoice = {
  invoiceid: string;
  invoicenumber: number;
  currencyid: string;
  currencyname: string;
  currencynamesys: string;
  currencynameshort: string;
  invoicepaymentstatusid: string;
  invoicepaymentstatustype: InvoicePaymentStatusType;
  invoicepaymentstatusname: string;
  organizationid: string;
  organizationname: string;
  comment: string;
  cnt: number;
  price: number;
  paymentid: string;
  recorddatecreate: Date;
};

export type IInvoiceList = {
  totalcount: number;
  items: IInvoice[];
};

export type IInvoiceItem = {
  invoiceitemid: string;
  invoiceid: string;
  nomenclatureid: string;
  nomenclaturename: string;
  nomenclaturenamefull: string;
  name: string;
  namefull: string;
  cnt: number;
  price: number;
  measurename: string;
  images: any[];
};

export type NewInvoice = {
  buyer: {
    buyertype: InvoiceBuyerType;
    organizationid: string | null;
  };
  payment: {
    paymenttype: InvoicePaymentType;
    paymentmethod: InvoicePaymentMethod;
  };
};

export enum InvoiceBuyerType {
  Corporate,
  Personal,
}
export enum InvoicePaymentType {
  PostPayment,
  PrePayment,
}
export enum InvoicePaymentMethod {
  Yookassa,
  Bill,
  PayKeeper,
}
export enum InvoicePaymentStatusType {
  Initial,
  PaymentExpected,
  Paid,
  Canceled,
}

export class InvoiceService {
  static async getAll(offset: number, limit: number): Promise<AxiosResponse<IInvoiceList>> {
    return $api.get<IInvoiceList>("/invoice", { params: { offset, limit } });
  }

  static async getAllItems(invoiceid: string): Promise<AxiosResponse<IInvoiceItem[]>> {
    return $api.get<IInvoiceItem[]>(`/invoice/${invoiceid}`);
  }

  static async makeFromCart(comment: string, organizationid: string | null, placeid: string): Promise<AxiosResponse<IInvoice>> {
    const res = $api.post<IInvoice>(`/invoice/make`, { comment, organizationid, placeid });
    return res;
  }
  static async create(invoice: NewInvoice, placeid: string): Promise<AxiosResponse<{ invoice: IInvoice }>> {
    const res = $api.post<{ invoice: IInvoice }>(`invoice/create`, { ...invoice, placeid });
    return res;
  }

  static async updateOrganization(invoiceid: string, organizationid: string): Promise<AxiosResponse<IInvoice>> {
    const res = $api.post<IInvoice>(`/invoice/updateorganization`, { invoiceid, organizationid });
    return res;
  }

  // Bill
  static async downloadBill(invoicenumber: string): Promise<AxiosResponse> {
    return $api.get(`invoice/bill/${invoicenumber}`);
  }
  static async createBill(invoiceid: string) {
    $api.post(`invoice/bill`, { invoiceid });
  }
}
