import { create } from "zustand";

import CartService from "../../services/CartService";

export type CartItemStore = {
  cartid: string;
  nomenclatureid: string;
  cnt: number;
  temporarysum: number;

  nomenclaturename: string;
  nomenclatureimageid: string;
};

interface CartState {
  cart: CartItemStore[];
}

interface CartAction {
  get: (placeid: string | null) => void;
  insert: (nomenclatureId: string, placeid: string) => Promise<void>;
  remove: (nomenclatureId: string) => Promise<void>;
  increment: (nomenclatureId: string, placeid: string) => Promise<void>;
  decrement: (nomenclatureId: string) => Promise<void>;
  clear: () => Promise<void>;
}

const cartInit: CartItemStore[] = [];

const useCartStore = create<CartState & CartAction>()((set, get) => ({
  cart: cartInit,

  get: async (placeid) => {
    if (!placeid) return;
    await CartService.getAll(placeid)
      .then((res) => set({ cart: res.data }))
      .catch((e) => alert("error"));
  },

  insert: async (nomenclatureId: string, placeid: string) => {
    await CartService.insert(nomenclatureId, placeid)
      .then((res) => set({ cart: [...get().cart, res.data] }))
      .catch((e) => alert("error"));
  },

  remove: async (nomenclatureId) => {
    await CartService.delete(nomenclatureId)
      .then(() => set({ cart: get().cart.filter((item) => item.nomenclatureid !== nomenclatureId) }))
      .catch((e) => alert("error"));
  },

  increment: async (nomenclatureId: string, placeid: string) => {
    await CartService.increment(nomenclatureId, placeid)
      .then(() => {
        set({ cart: get().cart.map((i) => (i.nomenclatureid === nomenclatureId ? { ...i, cnt: i.cnt + 1 } : i)) });
      })
      .catch((e) => alert("error"));
  },

  decrement: async (nomenclatureId) => {
    await CartService.decrement(nomenclatureId)
      .then(() => {
        set({ cart: get().cart.map((i) => (i.nomenclatureid === nomenclatureId ? { ...i, cnt: i.cnt - 1 } : i)) });
      })
      .catch((e) => alert("error"));
  },

  clear: async () => {
    await CartService.clear()
      .then(() => set({ cart: [] }))
      .catch((e) => alert("error"));
  },
}));
export default useCartStore;
