import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy: React.FC = () => {
  return (
    <Container className="privacy text-b my-5 fs-5">
      <h2 className="text-center mb-5">ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ С КОНЕЧНЫМ ПОЛЬЗОВАТЕЛЕМ</h2>
      <h5 className="fw-bold text-center">
        <p>ВНИМАТЕЛЬНО ОЗНАКОМЬТЕСЬ С ЛИЦЕНЗИОННЫМ СОГЛАШЕНИЕМ НА ИСПОЛЬЗОВАНИЕ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ Quick Launch!</p>
      </h5>
      <ul className="text-full-width" style={{ listStyle: "none" }}>
        <li>
          <p>
            1. Исключительное право на программное обеспечение Quick Launch (далее – ПО) принадлежат ООО «Негоциант» (далее –
            Правообладатель).
          </p>
        </li>
        <li>
          <p>
            2. Настоящее лицензионное соглашение с конечным пользователем (далее - Соглашение) заключается с целью установления прав на
            программное обеспечение и документацию, записанные на соответствующих носителях или доступные для загрузки по сети Интернет, а
            также электронную лицензию и/или ключ защиты, определяющий вариант поставки ПО и срок действия лицензии, и является офертой ООО
            «Негоциант» к физическому или юридическому лицу (далее – Пользователь).
          </p>
        </li>
        <li>
          <p>
            3. Установка ПО в память ЭВМ рассматривается как безусловное согласие Пользователя с условиями настоящего Соглашения. В случае
            несогласия с каким-либо из условий настоящего Соглашения Пользователь не имеет права продолжать установку ПО.
          </p>
        </li>
        <li>
          <p>
            4. Настоящее Соглашение предоставляет Пользователю право установки (инсталляции), запуска и использования одного экземпляра ПО
            на одну ЭВМ, в рамках функциональных возможностей, в соответствии с назначением и правилами пользования ПО, изложенными в
            эксплуатационной документации.
          </p>
        </li>
        <li>
          <p>
            5. Пользователь имеет право использовать ПО на правах простой (неисключительной) лицензии с момента активации ПО, приобретенного
            у Правообладателя или у официального партнера, дистрибьютора в течение срока действия приобретенной лицензии.
          </p>
        </li>
        <li>
          <p>
            6. Моментом активации ПО считается дата скачивания файла с сайта{" "}
            <a href="http://neg24.ru/">
              <u>http://neg24.ru.</u>
            </a>
          </p>
        </li>
        <li>
          <p>
            7. Лицензии действительны в течение 1 календарного года с момента активации ПО, кроме случаев замены оборудования, на котором
            используется ПО, при невозможности деактивации лицензии на старом.
          </p>
        </li>
        <li>
          <p>
            8. Пользователь обязуется не модифицировать, не адаптировать, не выполнять обратное проектирование, не декомпилировать, не
            дизассемблировать, не расшифровывать, не копировать, не создавать производные продукты, не транслировать ПО или документацию,
            или в ином порядке пытаться извлечь исходный код ПО, не вносить какие-либо изменения в объектный код ПО, в том числе не поручать
            иным лицам осуществлять указанные действия без согласия Правообладателя, за исключением тех случаев, когда указанные действия
            необходимы для осуществления Лицензиатом лицензионных прав, предусмотренных условиями Соглашения.
          </p>
        </li>
        <li>
          <p>9. Пользователь обязуется не совершать действия, нарушающие исключительные и авторские права Правообладателя.</p>
        </li>
        <li>
          <p>
            10. Настоящее Соглашение распространяет свое действие на весь период использования ПО. При прекращении использования ПО
            Пользователь обязан удалить ПО из памяти ЭВМ.
          </p>
        </li>
        <li>
          <p>
            11. Правообладатель не несет ответственность за фактические, косвенные и второстепенные убытки, включая в числе прочего
            упущенную выгоду и убытки в результате: потери прибыли, потери доходов, потери и получения доступа к конфиденциальной
            информации, персональных данных и иной информации, прерывания деятельности, в результате любых других материальных или иных
            убытков, возникающих в связи с использованием или невозможностью использования ПО или документации, а также при использовании
            стороннего программного обеспечения, предоставляемого третьими лицами.
          </p>
        </li>
        <li>
          <p>
            12. При нарушении Пользователем условий Соглашения, порядка использования ПО, Правообладатель вправе прекратить и/или отказать в
            предоставлении прав на использование ПО без возмещения каких-либо убытков.
          </p>
        </li>
        <li>
          <p>
            13. ПО предоставляется по принципу «как есть» и Правообладатель не гарантирует, что все его функциональные возможности будут
            отвечать ожиданиям Пользователя или смогут быть применимы для конкретной его цели.
          </p>
        </li>
        <li>
          <p>
            14. Действие настоящего Соглашения распространяется на все последующие обновления/новые версии ПО. Соглашаясь с установкой
            обновления/новой версии ПО, Пользователь принимает условия настоящего Соглашения для соответствующих обновлений/новых версий ПО,
            если обновление/установка новой версии ПО не сопровождается иным лицензионным соглашением.
          </p>
        </li>
        <li>
          <p>
            15. Техническая поддержка оказывается Правообладателем по каналам, указанным на сайте{" "}
            <a href="https://neg24.ru/contacts">
              <u>https://neg24.ru/contacts</u>
            </a>
          </p>
        </li>
        <li>
          <p>
            16. Настоящее лицензионное соглашение может изменяться Правообладателем в одностороннем порядке. Уведомление Пользователя о
            внесенных изменениях в условия настоящей лицензии публикуется на новостной странице сайта Правообладателя{" "}
            <a href="http://neg24.ru/news">
              <u>http://neg24.ru/news</u>
            </a>
            . Актуальная версия лицензионного соглашения доступна на сайте Правообладателя в разделе «Лицензионное соглашение»{" "}
            <a href="https://neg24.ru/privacy">
              <u>https://neg24.ru/privacy</u>
            </a>{" "}
            и дистрибутивах ПО.
          </p>
        </li>
        <li>
          <p>
            17. Указанные изменения в условиях лицензионного соглашения вступают в силу с даты их публикации, если иное не оговорено в
            соответствующей публикации.
          </p>
        </li>
        <li>
          <p>
            18. Принимая настоящее Соглашение Пользователь подтверждает свое согласие на получение рекламных сообщений от Правообладателя по
            электронной почте.
          </p>
        </li>
      </ul>
      <div className="text-center ">
        <p>ООО «Негоциант» ©</p>
        <p>Адрес: 660022, Красноярский край, г.Красноярск, ул.Партизана Железняка, д.19В , пом.127</p>
        <p>ИНН/КПП 2465328990 / 246501001 ОГРН 1192468041941</p>

        <a href="tel:+7(391)203-00-15">+7(391)203-00-15</a>
      </div>
      {/* <h3>
        Лицензионное соглашение на использование программного продукта <b>Quick Launch</b>.
      </h3>
      <p>
        Настоящее соглашение о лицензировании программного обеспечения является соглашением между конечным пользователем и правообладателем. Решив установить программный продукт <b>Quick Launch</b> на
        свой компьютер, конечный пользователь обязуется выполнять все приведенные условия этого соглашения.
      </p>
      <p>
        1. Это лицензионное соглашение гарантирует полное взаимопонимание между конечным пользователем <b></b> и правообладателем, и может изменяться только в письменном соглашении, подписанном обеими
        сторонами. НИКАКОЙ ПРОДАВЕЦ, ДИСТРИБУТОР, ДИЛЕР, РОЗНИЧНЫЙ ТОРГОВЕЦ, КОММЕРЧЕСКОЕ ИЛИ ДРУГОЕ ЛИЦО НЕ УПОЛНОМОЧЕН ИЗМЕНЯТЬ ДАННОЕ СОГЛАШЕНИЕ ИЛИ ДАВАТЬ ЛЮБЫЕ ДРУГИЕ ГАРАНТИИ ИЛИ ОБЕЩАНИЯ,
        ЯВЛЯЮЩИЕСЯ ОТЛИЧНЫМИ, ОТ ГАРАНТИЙ ИЛИ ОБЕЩАНИЙ ДАННОГО СОГЛАШЕНИЯ.
      </p>
      <p>
        2. Размещенное на сайте{" "}
        <u>
          <a className="text-b" href="https://neg24.ru">
            https://neg24.ru
          </a>
        </u>{" "}
        предложение о приобретении неисключительного права пользования программным продуктом <b>Quick Launch</b> сроком 1 год в соответствии со статьей 435 Гражданского кодекса РФ признается офертой.
      </p>
      <p>
        В соответствии со статьей 438 Гражданского кодекса РФ безусловным принятием (акцептом) условий настоящего Лицензионного соглашения считается факт приобретения пакета лицензий на сайте{" "}
        <u>
          <a className="text-b" href="https://neg24.ru">
            https://neg24.ru
          </a>
        </u>
        . Настоящее Лицензионное соглашение, заключаемое путем акцепта оферты, не требует двустороннего подписания и действительно в электронном виде.
      </p>
      <p>
        3. Пользователь вправе произвести установку <b>Quick Launch</b> на ограниченное количество настольных компьютеров и серверов в соответствии с приобретенным пакетом лицензий.
      </p>
      <p>
        4. Пользователь может использовать <b>Quick Launch</b>, но при этом не становится его владельцем. Все права собственности на <b>Quick Launch</b>, документацию и тексты в печатном и/или
        электронном виде, входящие в состав <b>Quick Launch</b>, сохраняются за правообладателем.
      </p>
      <p>
        5. Правообладатель оказывает услуги по техническому обслуживанию <b>Quick Launch</b>. Порядок обращения пользователя за техническим обслуживанием: вопросы, возникающие в ходе работы с ПО
        <b>Quick Launch</b> 4, следует направлять в службу поддержки через:
      </p>
      <ul>
        <li>
          <p>
            • e-mail: <u>support@neg24.ru</u>
          </p>
        </li>
        <li>
          <p>• Телефон +7 (391) 203-00-15</p>
        </li>
      </ul>
      <p>
        Данные контакты указаны на основном сайте компании{" "}
        <u>
          <a className="text-b" href="https://neg24.ru">
            https://neg24.ru
          </a>
        </u>
      </p>
      <p>
        Прием и обработка запросов осуществляется по рабочим дням с 08:00 до 17:00 по Московскому времени. Любые дополнительные программы и исходные тексты, переданные Пользователю в порядке оказания
        услуг по техническому обслуживанию, должны рассматриваться как составная часть <b>Quick Launch</b> и подпадают таким образом под действие ограничений и условий данного соглашения.
      </p>
      <p>6. Пользователю не разрешается:</p>
      <div className="ps-4">
        <p>
          6.1. Продавать <b>Quick Launch</b>, сдавать в аренду, давать в прокат или во временное пользование.
        </p>
        <p>6.2. Перепроектировать, перекомпилировать, дизассемблировать, вносить изменения, модифицировать для несовместимых аппаратных средств и платформ, а также создавать производные продукты.</p>
        <p>6.3. Копировать документацию или иные печатные материалы, прилагаемые к продукту.</p>
      </div>
      <p>8. Производитель не гарантирует, что приобретенный Пользователем продукт свободен от ошибок.</p>
      <p>
        9. Производитель не несёт ответственности за какой-либо ущерб, непредвиденные или косвенные убытки любого вида, связанные с использованием или невозможностью использования <b>Quick Launch</b>.
      </p>
      <p>
        10. Данное лицензионное соглашение регламентируется законодательством Российской Федерации. Стороны соглашаются, что любое действие, нарушающее это Лицензионное соглашение, должно быть
        наказуемо, и преследуемо в соответствии с законодательством РФ.
      </p>
      <p>
        11. Если вы не согласны с условиями данного лицензионного соглашения, то должны удалить файлы <b>Quick Launch</b> со своих устройств хранения информации и отказаться от использования продукта.
      </p>
      <p>© ООО Негоциант 2022, Волков В.Д.</p>
      <p>
        Для получения более полной информации о продукте посетите наш сайт:{" "}
        <u>
          <a className="text-b" href="https://neg24.ru">
            https://neg24.ru
          </a>
        </u>
      </p> */}
    </Container>
  );
};

export default PrivacyPolicy;
