import { create } from "zustand";
import Cookies from "js-cookie";
import UserProfileService from "../../services/UserProfileService";

export type UserState = {
  userprofileid: string;
  email: string;
};

type State = {
  userInit: UserState;
  user: UserState;
};

type Action = {
  updateUser: (user: State["user"]) => void;
  setInitUser: () => void;
  getUser: (userprofileid: string, email: string) => void;
  logout: () => void;
};

const userInit: UserState = {
  userprofileid: "",
  email: "",
};

const useUserStore = create<State & Action>()((set) => ({
  user: userInit,
  userInit: userInit,
  updateUser: (user) => set(() => ({ user: { ...user } })),
  setInitUser: () => set(() => ({ user: { ...userInit } })),
  getUser: async (userprofileid: string, email: string) => {
    await UserProfileService.getUser()
      .then((res) => {
        set({
          user: {
            userprofileid: userprofileid,
            email: email,
          },
        });
      })
      .catch((e) => alert("error"));
  },
  logout: () => {
    set(() => ({ user: userInit }));
    localStorage.removeItem("accessToken");
    Cookies.remove("accessToken");
  },
}));
export default useUserStore;
