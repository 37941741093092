import $api from "../http";
import { AxiosResponse } from "axios";
import { IAuthResponse } from "../models/AuthResponse";

export default class AuthService {
  static async login(email: string, password: string, placeid: string): Promise<AxiosResponse<IAuthResponse>> {
    return $api.post<IAuthResponse>("auth/login", { email, password, placeid });
  }

  static async registration(
    email: string,
    password: string,
    surname: string,
    name: string,
    middlename: string,
    phone: string,
    placeid: string
  ): Promise<AxiosResponse<IAuthResponse>> {
    return $api.post<IAuthResponse>("auth/registration", { email, password, surname, name, middlename, phone, placeid });
  }

  static async logout(): Promise<void> {
    return $api.post("auth/logout");
  }

  static checkLogin(): Promise<AxiosResponse> {
    return $api.get("auth/checklogin");
  }
}
