import { create } from "zustand";
import CurrencyService from "../../services/CurrencyService";

export type CurrencyState = {
  name: string;
  namesys: string;
  nameshort: string;
};

type State = {
  currency: CurrencyState;
};

type Action = {
  updateCurrency: (currency: State["currency"]) => void;
  getCurrency: (placeid: string) => void;
};

const currencyInit: CurrencyState = {
  name: "",
  nameshort: "",
  namesys: "",
};

const useCurrencyStore = create<State & Action>()((set) => ({
  currency: currencyInit,
  updateCurrency: (currency) => set(() => ({ currency: { ...currency } })),
  getCurrency: async (placeid) => {
    await CurrencyService.getCurrency(placeid)
      .then((res) => {
        set({ currency: res.data });
      })
      .catch((e) => alert("error"));
  },
}));
export default useCurrencyStore;
