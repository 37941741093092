import $api from "../http";
import { AxiosResponse } from "axios";

export type ICartItem = {
  cartid: string;
  nomenclatureid: string;
  cnt: number;
  temporarysum: number;

  nomenclaturename: string;
  nomenclatureimageid: string;
};

export default class CartService {
  // Получение списка все элементв корзины
  static async getAll(placeid: string): Promise<AxiosResponse<ICartItem[]>> {
    return await $api.get<ICartItem[]>(`cart`, { params: { placeid } });
  }

  static async check(placeid: string): Promise<AxiosResponse<boolean>> {
    return await $api.get<boolean>(`cart/check`, { params: { placeid } });
  }

  static async insert(nomenclatureid: string, placeid: string): Promise<AxiosResponse<ICartItem>> {
    return await $api.post<ICartItem>(`cart`, { nomenclatureid, placeid });
  }

  static async delete(nomenclatureid: string): Promise<AxiosResponse> {
    return await $api.delete(`cart/${nomenclatureid}`);
  }

  static async increment(nomenclatureid: string, placeid: string): Promise<AxiosResponse> {
    return await $api.put(`cart/increment`, { nomenclatureid, placeid });
  }

  static async decrement(nomenclatureid: string): Promise<AxiosResponse> {
    return await $api.put(`cart/decrement`, { nomenclatureid });
  }

  static async clear(): Promise<AxiosResponse<string>> {
    return await $api.delete<string>(`cart/clear`);
  }
}
