import { AxiosResponse } from "axios";

import $api from "../http";

interface IPlace {
  placeid: string;
  name: string;
  currencyid: string;
  shopnamesys: string;
  namesys: string;
  isdefault: boolean;
}

export class ShopService {
  // Получение всех городов магазина
  static async getAllShopPlaces(): Promise<AxiosResponse<IPlace[]>> {
    return await $api.get<IPlace[]>(`shop/place`);
  }

  // Получение города магазина
  static async getOneShopPlace(placeid: string): Promise<AxiosResponse<IPlace>> {
    return await $api.get<IPlace>(`shop/place/placeid`, { params: { placeid } });
  }

  // Получение города магазина по названию
  static async getOneShopPlaceByName(placename: string): Promise<AxiosResponse<IPlace>> {
    return await $api.get<IPlace>(`shop/place/placename`, { params: { placename } });
  }

  // Получение города магазина по названию
  static async getOneShopPlaceDefault(): Promise<AxiosResponse<IPlace>> {
    return await $api.get<IPlace>(`shop/place/default`);
  }

  // Получение города магазина по названию
  static async getAllShopCurrency(): Promise<AxiosResponse<any[]>> {
    return await $api.get<any[]>(`shop/currency`);
  }
}
