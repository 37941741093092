// import Loadable from 'react-loadable';
import { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "./pages/NotFound";
import InvoicePage from "./pages/InvoicePage";
import SuccessPay from "./pages/SuccessPay";
import Test from "./pages/Test";

import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/app.scss";
import MainLayout from "./layouts/MainLayout";

import Cookies from "js-cookie";
import UserProfilePage from "./pages/UserProfilePage";
import Contacts from "./pages/Contacts";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import dateLocalize from "./utils/dateLocalize";
import ShowCaseDownloadList from "./pages/ShowCaseDownload";
import ClientShop from "./pages/ClientShop";
import OrganizationList from "./pages/OrganizationPage";
import CartOrder from "./pages/CartPage/CartOrder";
import MainPage from "./pages/MainPage";
import News from "./pages/News";
// import ClientLicSchemeList from "./pages/ClientLicScheme";
import Terms from "./pages/Terms";
import Loader from "./components/Loader/Loader";
import AuthService from "./services/AuthService";
import PaymentTerms from "./pages/PaymentTerms";
import { CartPage } from "./pages/CartPage/CartPage";
import useCartStore from "./zustand/cart/cart-store";
import usePlaceStore from "./zustand/place/place-store";
import useCurrencyStore from "./zustand/currency/currency-store";
import useUserStore from "./zustand/user/user-store";
import { NomenclaturePage } from "./pages/NomenclaturePage/NomenclaturePage";

dateLocalize();

function App() {
  const { place, getDefault, getOne } = usePlaceStore((state) => state);
  const { getCurrency, updateCurrency } = useCurrencyStore((state) => state);
  const { user, getUser, setInitUser } = useUserStore((state) => state);
  const { get: getCart, clear: clearCart } = useCartStore((state) => state);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const placeid = window.localStorage.getItem("placeid");
    if (!placeid) getDefault().then((placeid) => getCurrency(placeid));
    if (placeid) {
      getOne(placeid);
      getCurrency(placeid);
    } else updateCurrency({ name: "", nameshort: "", namesys: "" });
  }, []);

  useEffect(() => {
    const cookie_accessToken = Cookies.get("accessToken");
    if (cookie_accessToken) localStorage.setItem("accessToken", cookie_accessToken);

    Cookies.remove("accessToken");

    const accessToken = localStorage.getItem("accessToken");

    if (accessToken && !user.userprofileid) {
      AuthService.checkLogin()
        .then((res) => {
          getUser(res.data.userprofileid, res.data.email);
          if (place) getCart(place.placeid);
        })
        .catch((e) => {
          setInitUser();
          clearCart();
          alert("error");
        })
        .finally(() => setLoading(false));
    } else if (loading) {
      setLoading(false);
    }
  }, [place]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path="order" element={<CartOrder />} />
      <Route path="/" element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <MainPage />
            </Suspense>
          }
        />
        <Route path="showcasedownload" element={<ShowCaseDownloadList />} />

        <Route
          path="shop"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <ClientShop />
            </Suspense>
          }
        />
        <Route
          path="news"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <News />
            </Suspense>
          }
        />
        <Route
          path="nomenclature/:id"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <NomenclaturePage />
            </Suspense>
          }
        />

        <Route
          path="test"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <Test />
            </Suspense>
          }
        />

        <Route
          path="contacts"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <Contacts />
            </Suspense>
          }
        />
        <Route
          path="privacy"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="terms"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <Terms />
            </Suspense>
          }
        />
        <Route
          path="payment-terms"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <PaymentTerms />
            </Suspense>
          }
        />

        {user && (
          <>
            <Route
              path="userprofile"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <UserProfilePage />
                </Suspense>
              }
            />
            <Route
              path="invoice"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <InvoicePage />
                </Suspense>
              }
            />
            <Route
              path="organization"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <OrganizationList />
                </Suspense>
              }
            />
            {/* <Route
              path="license"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <ClientLicSchemeList />
                </Suspense>
              }
            /> */}{" "}
            {/* ожидает бэкэнда */}
            <Route
              path="cart"
              element={
                <Suspense fallback={<div>Идёт загрузка корзины...</div>}>
                  <CartPage />
                </Suspense>
              }
            />
            <Route
              path="success_pay"
              element={
                <Suspense fallback={<div>Идёт загрузка...</div>}>
                  <SuccessPay />
                </Suspense>
              }
            />
          </>
        )}
        <Route
          path="*"
          element={
            <Suspense fallback={<div>Идёт загрузка...</div>}>
              <NotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
