import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import OrganizationService, { Organization } from "../../services/OrganizationService";

export enum OrganizationControlState {
  add,
  edit,
}

export type OrganizationControlProps = {
  state: OrganizationControlState;
  organization: Organization;
  saveCallback: (organization: Organization) => void;
  cancelCallback: () => void;
};

const OrganizationControl: React.FC<OrganizationControlProps> = ({ state, organization, saveCallback, cancelCallback }) => {
  const [candidat, setCandidat] = React.useState(organization);

  const saveOrganization = () => {
    if (state === OrganizationControlState.add) {
      insertOrganization();
    } else {
      updateOrganization();
    }
  };
  const insertOrganization = () => {
    OrganizationService.organizationInsert(candidat)
      .then((res) => {
        saveCallback(res.data);
      })
      .catch((e: any) => {
        if (e.response) {
          console.error(e.response.data);
        } else {
          console.error("Error", e.message);
        }
      });
  };
  const updateOrganization = () => {
    OrganizationService.organizationUpdate(candidat)
      .then((res) => {
        saveCallback(res.data);
      })
      .catch((e: any) => {
        if (e.response) {
          console.error(e.response.data);
        } else {
          console.error("Error", e.message);
        }
      });
  };
  return (
    <>
      <h3 className="ms-2 mt-5">{state === OrganizationControlState.add ? "Добавление" : "Редактирование"} юр. лица</h3>
      <Row className="mb-3 align-items-center">
        <Col>
          <span className="ms-2 text-b">Наименование:</span>
        </Col>
        <Col xs={12} md={8} lg={6} xl={6}>
          <input
            type="text"
            className="form-control"
            value={candidat.name}
            onChange={(e) => setCandidat({ ...candidat, name: e.target.value })}
          />
        </Col>
      </Row>
      <Row className="mb-3 align-items-center">
        <Col>
          <span className="ms-2 text-b">Полное наименование:</span>
        </Col>
        <Col xs={12} md={8} lg={6} xl={6}>
          <input
            type="text"
            className="form-control"
            value={candidat.namefull}
            onChange={(e) => setCandidat({ ...candidat, namefull: e.target.value })}
          />
        </Col>
      </Row>
      <Row className="mb-3 align-items-center">
        <Col>
          <span className="ms-2 text-b">ИНН:</span>
        </Col>
        <Col xs={12} md={8} lg={6} xl={6}>
          <input
            type="text"
            className="form-control"
            value={candidat.inn}
            onChange={(e) => setCandidat({ ...candidat, inn: e.target.value })}
          />
        </Col>
      </Row>
      <Row className="mb-3 align-items-center">
        <Col>
          <span className="ms-2 text-b">КПП:</span>
        </Col>
        <Col xs={12} md={8} lg={6} xl={6}>
          <input
            type="text"
            className="form-control"
            value={candidat.kpp}
            onChange={(e) => setCandidat({ ...candidat, kpp: e.target.value })}
          />
        </Col>
      </Row>
      <div className="d-flex">
        <Button className="w-100 style-btn-action" onClick={saveOrganization}>
          Сохранить
        </Button>
        <Button className="w-100 style-btn-action" onClick={cancelCallback}>
          Отмена
        </Button>
      </div>
    </>
  );
};

export default OrganizationControl;
