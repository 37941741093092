import axios from "axios";
import config from "../config";

const $api = axios.create({
  withCredentials: true,
  baseURL: config.API_URL,
  headers: { shop: config.API_SHOP },
});

$api.interceptors.request.use(
  (config: any) => {
    if (typeof window !== "undefined") {
      const accessToken = localStorage.getItem("accessToken");
      const placeid = localStorage.getItem("placeid");
      if (placeid) {
        config.headers = {
          ...config.headers,
          Place: placeid,
        };
      }
      if (accessToken) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

$api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (typeof window !== "undefined") {
      const originalRequest = error.config;

      if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
          const response = await axios.get(`${config.API_URL}/refresh`, { withCredentials: true });
          localStorage.setItem("accessToken", response.data.accessToken);
          return $api.request(originalRequest);
        } catch (e) {
          localStorage.removeItem("accessToken");
          window.open(`/`, "_self");
        }
      }
      throw error;
    }
  }
);

export default $api;
