import React from "react";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import { InvoiceService, IInvoiceItem, IInvoice, InvoicePaymentStatusType, InvoicePaymentMethod } from "../../services/InvoiceService";
import nomenclatureNoImg from "../../assets/img/nomenclature_no_image.png";
import config from "../../config";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import pay from "../../assets/img/svg/pay.svg";
import downloadinvoice from "../../assets/img/svg/downloadinvoice.svg";
import { PaymentService } from "../../services/PaymentService";
import { createPaykeeperForm } from "../../utils/createPaykeeperForm";

// type InvoiceItemProps = {
//   recorddatecreate: Date;
//   invoiceid: string;
//   invoicenumber: number;
//   currencyid: string;
//   currencyname: string;
//   currencynameshort: string;
//   currencynamesys: string;
//   invoicepaymentstatusid: string;
//   invoicepaymentstatusname: string;
//   invoicepaymentstatusnamesys: string;
//   comment: string;
//   cnt: number;
//   price: number;
//   organizationid: string;
//   organizationname: string;
// };

export interface InvoiceItemProps {
  invoice: IInvoice;
}

export default function InvoiceItem({ invoice }: InvoiceItemProps) {
  const [items, setItems] = React.useState([] as IInvoiceItem[]);
  const [loading, setLoading] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [error, setError] = React.useState("");

  const onClick = () => {
    setItems([]);
    setError("");
    if (!expand) {
      setLoading(true);
      InvoiceService.getAllItems(invoice.invoiceid)
        .then((res) => {
          setItems(res.data);
        })
        .catch((e) => {
          if (e.response) {
            setError(e.response.data.message);
          } else {
            setError(e.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setExpand(!expand);
  };

  const continuePayment = (invoiceid: string) => {
    PaymentService.createPayment(invoiceid)
      .then((res) => {
        if (res.data.payment.paymentmethod === InvoicePaymentMethod.PayKeeper) {
          createPaykeeperForm(res.data.payment.paymentdata, res.data.payment.paymentlink);
        }
      })
      .catch(() => alert("Ошибка получения ссылки на повторную оплату"));
  };

  return (
    <div className="accordion-item accordion-button-invoice">
      <div
        onClick={onClick}
        className="accordion-button collapsed"
        id={"flush-heading" + invoice.invoicenumber}
        data-bs-toggle="collapse"
        data-bs-target={"#flush-collapse" + invoice.invoicenumber}
        aria-expanded="false"
        aria-controls={"flush-collapse" + invoice.invoicenumber}
        style={{ cursor: "pointer" }}
      >
        <Row className="justify-content-between align-items-center text-center w-100">
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="p-0">
            №{invoice.invoicenumber}
          </Col>
          <Col xs={3} sm={4} md={6} lg={5} xl={4} className="p-0">
            <Row className="align-items-center">
              <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                {invoice.invoicepaymentstatusname + " "}
              </Col>
              <Col xs={12} sm={6} md={7} lg={8} xl={8}>
                {invoice.invoicepaymentstatustype === InvoicePaymentStatusType.Initial && (
                  <>
                    {!invoice.organizationid && (
                      <Button
                        variant=""
                        className="w-100 style-btn-action "
                        onClick={(e) => {
                          continuePayment(invoice.invoiceid);
                        }}
                      >
                        <span className="text-white d-none d-md-inline-flex">Оплатить</span>
                        <img alt="pay" className="mx-2" src={pay} />
                      </Button>
                    )}
                    {invoice.organizationid && (
                      <Button
                        href={`${config.API_URL}/invoice/bill/${invoice.invoiceid}`}
                        className="w-100 style-btn-action "
                        onClick={(e) => e.stopPropagation()}
                      >
                        <span className="text-white d-none d-md-inline-flex">Скачать счет</span>
                        <img alt="downloadinvoice" className="mx-2" src={downloadinvoice} />
                      </Button>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1} xl={1} className="p-0">
            {invoice.cnt}
          </Col>
          <Col xs={3} sm={2} md={2} lg={2} xl={1} className="p-0 text-end" style={{ fontWeight: "bold" }}>
            {invoice.price.toLocaleString()} {invoice.currencynameshort}
          </Col>
          <Col lg={2} xl={4} className="d-none p-0 d-lg-block">
            {dateFormat(invoice.recorddatecreate, " d mmmm, yyyy, HH:MM")}
            {/* {recorddatecreate.toLocaleString()} */}
          </Col>
        </Row>
      </div>
      <div
        id={"flush-collapse" + invoice.invoicenumber}
        className="accordion-collapse collapse"
        aria-labelledby={"flush-heading" + invoice.invoiceid}
        data-bs-parent="#accordionFlushExample"
      >
        <div className="accordion-body mx-3">
          {expand && loading && (
            <Container className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">загрузка...</span>
              </Spinner>
            </Container>
          )}
          {expand && !loading && error && <Alert variant="danger">{error}</Alert>}
          {expand && !loading && !error && (
            <Row className="fw-bold justify-content-between" style={{ borderBottom: "2px solid black" }}>
              {invoice.organizationid && <p>Организация: {invoice.organizationname}</p>}
              <Col xs={3} sm={2} md={3} lg={3} xl={1} className="p-0">
                <p>Продукт</p>
              </Col>
              <Col xs={4} sm={2} md={3} lg={3} xl={6} className="p-0">
                <p>Наименование</p>
              </Col>
              <Col xs={2} sm={2} md={2} lg={3} xl={1} className="p-0 text-center">
                <p>Кол-во</p>
              </Col>
              <Col xs={2} sm={2} md={3} lg={3} xl={2} className="p-0 text-end">
                <p>Цена</p>
              </Col>
            </Row>
          )}
          {expand &&
            !loading &&
            !error &&
            items.map((item) => (
              <Row className="justify-content-between align-items-center" key={item.invoiceitemid}>
                <Col xs={3} sm={2} md={3} lg={3} xl={1} className="p-0 my-2">
                  {item.images.length > 0 ? (
                    <Link to={`/nomenclature/${item.nomenclatureid}`}>
                      <Image
                        className="d-block w-100 me-1"
                        src={`${config.API_URL}/nomenclature/image/` + item.images[0].imageid}
                        alt={item.images[0].name}
                      />
                    </Link>
                  ) : (
                    <img src={nomenclatureNoImg} className="d-block w-50" alt="notfoundImg" />
                  )}
                </Col>

                <Col xs={4} sm={2} md={3} lg={3} xl={6} className="p-0">
                  <Link to={`/nomenclature/${item.nomenclatureid}`}>
                    <p className="m-0">{item.nomenclaturename}</p>
                  </Link>
                </Col>

                <Col xs={2} sm={2} md={2} lg={3} xl={1} className="p-0 text-center">
                  <p className="m-0">{item.cnt}</p>
                </Col>
                <Col xs={2} sm={2} md={3} lg={3} xl={2} className="p-0 text-end" style={{ fontWeight: "bold" }}>
                  {" "}
                  <p className="m-0">
                    {item.price.toLocaleString()} {invoice.currencynameshort}
                  </p>
                </Col>
              </Row>
            ))}
        </div>
      </div>
    </div>
  );
}
