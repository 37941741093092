import { create } from "zustand";
import { ShopService } from "../../services/ShopService";

export type PlaceState = {
  placeid: string;
  name: string;
  currencyid: string;
  shopnamesys: string;
  namesys: string;
  isdefault: boolean;
};

type State = {
  placeList: PlaceState[];
  place: PlaceState | null;
};

type Action = {
  getAll: () => Promise<PlaceState[]>;
  getOne: (placeid: string) => void;
  getOneByName: (placename: string) => void;
  getDefault: () => Promise<string>;
  updatePlace: (place: PlaceState) => void;
  deltePlace: () => void;
};

const usePlaceStore = create<State & Action>()((set, get) => ({
  placeList: [],
  place: null,

  updatePlace: (place) => {
    set({ place: place });
  },
  deltePlace: () => {
    set({ place: null });
  },

  getAll: async () => {
    const res = await ShopService.getAllShopPlaces();
    set({ placeList: res.data });
    if (res.data.length === 0) {
      window.localStorage.removeItem("placeid");
      set({ place: null });
    }
    return res.data;
  },

  getOne: async (placeid) => {
    const res = await ShopService.getOneShopPlace(placeid);
    set({ place: res.data });
    window.localStorage.setItem("placeid", res.data.placeid);
    return res.data.placeid;
  },

  getOneByName: async (placename) => {
    const res = await ShopService.getOneShopPlaceByName(placename);
    set({ place: res.data });
    window.localStorage.setItem("placeid", res.data.placeid);
    return res.data.placeid;
  },

  getDefault: async () => {
    const res = await ShopService.getOneShopPlaceDefault();
    set({ place: res.data });
    window.localStorage.setItem("placeid", res.data.placeid);
    return res.data.placeid;
  },
}));
export default usePlaceStore;
